<script setup lang="ts">
const links = [
  'Sagesse',
  'Lien',
  'Bienveillance',
  'Facilitation',
  'Transmission',
]
</script>

<template>
  <footer class="flex items-center justify-center from-lemon to-sunset bg-gradient-to-b font-gravity">
    <div class="grid my-3 items-center justify-center gap-4 text-slate-700 container xl:grid-cols-5 xl:gap-2 children:uppercase">
      <div v-for="link in links" :key="link" class="flex items-center justify-start gap-4 tracking-widest xl:justify-center">
        <NuxtImg src="/img/eliah_picto-violetfonce.png" width="50" height="50" alt="Picto Eliah Sunset" format="webp" quality="80" class="h-10 w-10" />
        {{ link }}
      </div>
    </div>
  </footer>
</template>
