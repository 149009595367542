<script setup lang="ts">
import { useToggle } from '@vueuse/core'

const [showMenu, toggleMenu] = useToggle()
</script>

<template>
  <div v-auto-animate class="w-full">
    <div key="header" class="lt-xl:justify-space-between mx-3 my-3 gap-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lt-xl:flex lt-xl:items-center">
      <div class="lt-2xl:hidden" />

      <div class="flex items-center xl:w-full xl:justify-center lt-xl:flex-1 lt-xl:grow-5">
        <NuxtLink to="/">
          <NuxtImg
            class="max-h-20 w-full object-contain lt-xl:object-top-left"
            src="/img/eliah-logo-hjt.png"
            alt="Eliah Logo"
            sizes="sm:40vw md:40vw lg:40vw xl:40vw"
            quality="80"
            format="webp"
          />
        </NuxtLink>
      </div>

      <div class="w-full flex items-center justify-end gap-3 lt-xl:hidden">
        <VBtn pre-icon="i-material-symbols:mail" href="mailto:info@eliah-institut.fr" class="mr-4">
          Contactez-nous
        </VBtn>
      </div>

      <div class="w-full flex flex-1 items-center justify-end xl:hidden">
        <VBtnIcon icon="i-ic:round-menu" @click="toggleMenu()" />
      </div>
    </div>

    <div v-if="showMenu" key="mobile-menu" class="flex flex-col items-center justify-center gap-3 bg-void-400 pb-3 pt-6 xl:hidden">
      <VBtn class="uppercase" pre-icon="i-ep:platform">
        Mon espace accompagnant
      </VBtn>

      <div>
        <VBtnIcon icon="i-ph:linkedin-logo-bold" href="https://www.linkedin.com/company/eliah-institut" target="_blank" />
      </div>
    </div>
  </div>
</template>
