<script setup lang="ts">

</script>

<template>
  <div class="min-h-screen w-full flex flex-col bg-void text-slate-200">
    <VLayoutHeader />

    <main class="flex grow flex-col lt-xl:min-h-screen">
      <slot />
    </main>

    <VLayoutFooter />
  </div>
</template>
